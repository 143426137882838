import * as React from "react";
import {
	AllOrNone,
	DataObserveKey,
	VisualComponent,
	FocusableComponent,
	Icon,
	IconSortableAsc,
	IconSortableDesc,
	IconSortable,
	Button,
	InlineText,
	Tooltip,
	useStaticMode,
	cn,
} from "@siteimprove/fancylib";
import { useLabTranslations } from "../../../translations/translations";
import {
	BasicHeaderConfig,
	HeaderConfig,
	HeaderConfigTooltip,
	SortableHeaderConfig,
} from "../base-table/base-table";
import * as scss from "./column-header.scss";

export function isSortable<TDto>(
	props: HeaderConfig<TDto> | SortableHeaderConfig<TDto> | BasicHeaderConfig
): props is SortableHeaderConfig<TDto> {
	return "property" in props && !(props.notSortable === true);
}

export interface SortField<T> {
	/** Property the table is sorted by */
	property: Extract<keyof T, string>;
	/** Direction the table is sorted by */
	direction: "asc" | "desc";
}

type SortControl<T> = {
	/** Property and direction the table is currently sorted by */
	sort: SortField<T>;
	/** Callback for when a sort button is clicked */
	onSort: (property: Extract<keyof T, string>, direction: "asc" | "desc") => void;
};

type SortSpecifier<T> = {
	/** Sortable property */
	property: Extract<keyof T, string>;
	/** Default direction for sorting the property */
	defaultSortDirection?: "asc" | "desc";
};

type SortProps<T> = SortControl<T> & SortSpecifier<T>;

type Column<T> = {
	content: string;
	"data-observe-key"?: string;
} & SortSpecifier<T>;

type MultiColumnHeaderProps<T> = SortControl<T> & {
	/** Columns/properties within the column header */
	columns: Column<T>[];
	/** Tooltip to explain the column */
	tooltip?: HeaderConfigTooltip;
} & DataObserveKey &
	VisualComponent &
	FocusableComponent;

type BaseColumnHeaderProps<T> = {
	/** Content of the header cell */
	content: string;
	/** Tooltip to explain the column */
	tooltip?: HeaderConfigTooltip;
} & AllOrNone<SortProps<T>> &
	DataObserveKey &
	VisualComponent &
	FocusableComponent;

export function ColumnHeader<T>(props: BaseColumnHeaderProps<T>): JSX.Element {
	if (isSorty(props)) {
		const { property, content, defaultSortDirection, tooltip, onSort, sort, ...rest } = props;
		return (
			<MultiColumnHeader
				columns={[
					{
						property: property,
						content: content,
						defaultSortDirection: defaultSortDirection,
					},
				]}
				tooltip={tooltip}
				onSort={onSort}
				sort={sort}
				{...rest}
			/>
		);
	}
	const { content, tooltip, className, style, tabIndex, onFocus, onBlur, onKeyDown, onMouseDown } =
		props;
	return (
		<div
			data-component="column-header"
			data-observe-key={props["data-observe-key"]}
			className={cn(scss.columnHeader, className, scss.noSort)}
			style={style}
			tabIndex={tabIndex}
			onFocus={onFocus}
			onBlur={onBlur}
			onKeyDown={onKeyDown}
			onMouseDown={onMouseDown}
		>
			{tooltip ? (
				<Tooltip
					variant={{ type: "text", icon: <Icon size="small">{Tooltip.icon}</Icon> }}
					content={typeof tooltip === "string" ? tooltip : tooltip.content}
				>
					{content}
				</Tooltip>
			) : (
				content
			)}
		</div>
	);
}

export function MultiColumnHeader<T>(props: MultiColumnHeaderProps<T>): JSX.Element {
	const {
		columns,
		sort,
		onSort,
		tooltip,
		className,
		style,
		tabIndex,
		onFocus,
		onBlur,
		onKeyDown,
		onMouseDown,
	} = props;

	const i18n = useLabTranslations();

	const isStatic = useStaticMode();
	const colButtons = columns.map((column, key) => {
		const direction = sort.property == column.property ? sort.direction : undefined;

		const classNames = cn(
			scss.sortButton,
			"fancy-SortButton",
			!!direction && scss.sorted,
			!!direction && "fancy-SortButtonSorted",
			!!isStatic && scss.sortButtonDisabled
		);

		const header = (
			<>
				{isSortable(column) && (!isStatic || sort.property == column.property) && (
					<SortIndicator direction={direction} />
				)}
				<InlineText size="xSmall" lineHeight="multi-line">
					{column.content}
				</InlineText>
				{tooltip &&
					key === columns.length - 1 &&
					(typeof tooltip === "string" || !tooltip.icon ? (
						<Icon size="small">{Tooltip.icon}</Icon>
					) : (
						tooltip.icon
					))}
			</>
		);

		return isStatic ? (
			<div className={classNames}>{header}</div>
		) : (
			<Button
				size="small"
				key={key}
				data-observe-key={column["data-observe-key"]}
				variant="borderless"
				onClick={() => onSort(column.property, column.defaultSortDirection || "desc")}
				className={classNames}
				aria-roledescription={i18n.columnHeaderRoleDescription}
			>
				{header}
			</Button>
		);
	});
	const buttons = columns.length == 1 ? colButtons[0] : <Button.Group>{colButtons}</Button.Group>;

	return (
		<div
			data-component="multi-column-header"
			data-observe-key={props["data-observe-key"]}
			className={cn(scss.columnHeader, className)}
			style={style}
			tabIndex={tabIndex}
			onBlur={onBlur}
			onFocus={onFocus}
			onKeyDown={onKeyDown}
			onMouseDown={onMouseDown}
		>
			{tooltip ? (
				<Tooltip
					variant={{ type: "interactive" }}
					content={typeof tooltip === "string" ? tooltip : tooltip.content}
				>
					{buttons}
				</Tooltip>
			) : (
				buttons
			)}
		</div>
	);
}

function isSorty<T>(
	props: BaseColumnHeaderProps<T>
): props is BaseColumnHeaderProps<T> & SortProps<T> {
	return !!(props as SortProps<T>).sort;
}

export function BaseColumnHeader(
	props: { content: React.ReactNode } & DataObserveKey & VisualComponent & FocusableComponent
): JSX.Element {
	const { content, className, style, tabIndex, onBlur, onFocus, onKeyDown, onMouseDown } = props;
	return (
		<div
			data-observe-key={props["data-observe-key"]}
			data-component="base-column-header"
			aria-live="polite"
			aria-atomic="true"
			className={cn(scss.columnHeader, className)}
			style={style}
			tabIndex={tabIndex}
			onBlur={onBlur}
			onFocus={onFocus}
			onKeyDown={onKeyDown}
			onMouseDown={onMouseDown}
		>
			{content}
		</div>
	);
}

export type SortIndicatorDirection = "asc" | "desc" | undefined;
export type SortIndicatorProps = { direction?: SortIndicatorDirection; className?: string };

export const SortIndicator = (props: SortIndicatorProps): JSX.Element => {
	const { direction, className } = props;
	const icon =
		direction &&
		(direction === "asc" ? (
			<IconSortableAsc className={className} />
		) : (
			<IconSortableDesc className={className} />
		));

	return <Icon>{icon || <IconSortable className={className} />}</Icon>;
};
