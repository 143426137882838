import React, { useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import { Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { ColumnHeader } from "./column-header";

export const Meta: DocPageMeta = {
	category: "Tables and lists",
	title: "Column header",
	notepad: "https://hackmd.io/OHy7q4TvT9ScrpDg-dBh4A",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Column header"
			subTitle="Help users understand what information is in each table column. It can also allow users to sort the data in the column, making it easier to find what they're looking for."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={ColumnHeader} />
				<Header.H2>Examples</Header.H2>
				<Header.H3>Basic usage</Header.H3>
				<Paragraph>
					The basic variant displays the column label in a concise and easily readable manner.
				</Paragraph>
				<Paragraph>
					<b>Use cases</b>:
				</Paragraph>
				<Ul
					items={[
						<>
							When the column's content is readily apparent from its label (e.g., "Name," "Date,").
						</>,
						<>When sorting or additional information is not required.</>,
					]}
				/>
				<Example fn={BasicUsage} />
				<Header.H3>Usage with tooltip</Header.H3>
				<Paragraph>
					A variant with a tooltip provides supplemental information about the column's data. The
					tooltip appears on hover or focus.
				</Paragraph>
				<Paragraph>
					<b>Use cases</b>:
				</Paragraph>
				<Ul
					items={[
						<>When the column label needs clarification or additional context.</>,
						<>When explaining abbreviations or specialized terminology.</>,
					]}
				/>
				<Example fn={UsageWithTooltip} />
				<Header.H3>Usage with sorting</Header.H3>
				<Paragraph>
					A sortable variant allows users to sort table data in ascending or descending order by
					clicking on the header. Visual indicators (arrows) signify the current sorting state.
				</Paragraph>
				<Paragraph>
					<b>Use cases</b>:
				</Paragraph>
				<Ul
					items={[
						<>When users need to quickly organize and analyze table data.</>,
						<>When presenting large datasets that require flexible sorting options.</>,
					]}
				/>
				<Example fn={UsageWithSorting} />
				<Header.H2>Properties</Header.H2>
				<Knobs component={ColumnHeader} initialProps={{ content: "Header" }} />
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>ColumnHeader</Code> when
					</Paragraph>
					<Ul
						items={[
							<>
								You want to clearly label and organize data into columns within a table or grid
								structure.
							</>,
							<>
								You want to allow users to sort the data based on the values in a particular column.
							</>,
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>ColumnHeader</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								At the top of each column in a{" "}
								<GatsbyLink to="/lab/components/Tables and lists/Table">Table</GatsbyLink>.
							</>,
							<>In data grids or similar data display structures. e.g Dashboard widgets.</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>ColumnHeader</Code> to existing components for visual
								consistency.
							</>,
							<>
								Alignment:
								<Ul
									items={[
										"Left-align text labels for readability.",
										"Consider aligning numeric data to the right for easier comparison.",
									]}
								/>
							</>,
						]}
					/>
					<Header.H4>Interaction</Header.H4>
					<Ul
						items={[
							<>
								Click:
								<Ul
									items={[
										"Sortable headers should respond to clicks to change the sort order.",
										"Tooltips should appear on hover or focus, and disappear on mouse-out or focus loss.",
									]}
								/>
							</>,
							"Consider how column headers will adapt on smaller screens (e.g., wrapping text).",
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								The data isn't naturally organized into columns, and column headers won't be helpful
								e.g{" "}
								<GatsbyLink to="/lab/components/Tables and lists/List table ">
									List Table
								</GatsbyLink>
								.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul
						items={[
							"Ensure users can navigate to and interact with column headers using the keyboard.",
							"Use sufficient color contrast between text and background.",
							"Use descriptive tooltip text for screen readers.",
						]}
					/>
					<Header.H4>For developers</Header.H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"Keep labels brief but informative. Aim for 10-20 characters.",
							<>Use sentence case for labels (e.g., "Last name" not "Last Name").</>,
							<>
								Avoid excessive abbreviations or jargon. For instance, "SEO" for "search engine
								optimization" might be acceptable, while "CPC" for "cost per conversion" might not
								be.
							</>,
							<>
								In tooltip, provide enough context to clarify the column's meaning, but avoid long
								explanations and links.
							</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const BasicUsage = () => <ColumnHeader content="Table header" />;

const UsageWithTooltip = () => (
	<ColumnHeader content="Table header" tooltip="Some extra info about the column" />
);

const UsageWithSorting = () => {
	const [direction, setDirection] = useState<"asc" | "desc">("asc");
	const currentSort = { property: "property-name", direction: direction };
	return (
		<ColumnHeader
			content="Sortable header"
			sort={currentSort}
			onSort={() => setDirection(direction === "asc" ? "desc" : "asc")}
			property="property-name"
		/>
	);
};
