import React from "react";
import GatsbyLink from "gatsby-link";
import { H2, Paragraph, TextContainer } from "../../lib/src";
import { InlineMessage } from ".";

export function LabWarning(): JSX.Element {
	return (
		<div role="group" aria-label="Warning message">
			<InlineMessage variant="warning">
				<TextContainer article>
					<H2 lookalike="h6">This is a Lab component!</H2>
					<Paragraph>
						That means it doesn't satisfy our{" "}
						<GatsbyLink to="/Developing/adding-components">definition of done</GatsbyLink> and may
						be changed or even deleted. For an exact status, please reach out to the Fancy team
						through the <a href="https://siteimprove.slack.com/archives/CCH0J3U4E">dev_fancy</a> or{" "}
						<a href="https://siteimprove.slack.com/archives/CAVM2RC4F">ux_fancy</a> channels.
					</Paragraph>
				</TextContainer>
			</InlineMessage>
		</div>
	);
}
